import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

export const lang = process.env.GATSBY_LANG;

const pages = [
  'home',
  'about',
  'educators',
  'educators-your-heart-vft',
  'employees',
  'families',
  'paths',
  'common',
];

const resources = {};
pages.forEach((page) => {
  let translation = require(`./locales/${page}/${lang}.json`);

  if (!resources[lang]) {
    resources[lang] = {};
  }
  resources[lang][page] = {
    ...translation,
  };
});

i18n
  .use(initReactI18next)
  .init({
    debug: process.env.NODE_ENV === 'development',
    fallbackLng: lang,
    keySeparator: '^',
    returnObjects: true,
    resources,
    react: {
      useSuspense: false,
    },
  })
  .then();

i18n.loadNamespaces(pages);

export default i18n;
