import React, { useEffect, useState } from 'react';
import axios from 'axios';

/**
 * Per the React docs, this is ONLY used when an ancestor provider is not found.
 */
const defaultState = {
  ready: false,
  loggedIn: false,
  fullName: null,
  givenName: null,
  familyName: null,
  setLoggedIn: () => {},
  setFullName: () => {},
  setGivenName: () => {},
  setFamilyName: () => {},
};

/**
 * The actual context singleton.
 * You can import this to use the 'useContext' hook with a parent provider.
 */
const OauthContext = React.createContext(defaultState);

/**
 * The standard context provider you can use to wrap around children.
 * Children of this node can use the 'useContext' React hook to access the value.
 */
const OauthContextProvider = ({ children }) => {
  const [ready, setReady] = useState(false);
  const [loggedIn, setLoggedIn] = useState(false);
  const [fullName, setFullName] = useState(null);
  const [givenName, setGivenName] = useState(null);
  const [familyName, setFamilyName] = useState(null);
  const [providerValue, setProviderValue] = useState({
    loggedIn,
    fullName,
    givenName,
    familyName,
    setLoggedIn,
    setFullName,
    setGivenName,
    setFamilyName,
  });

  // componentDidMount
  useEffect(() => {
    axios
      .get('/app/oauth/user')
      .then((response) => {
        setLoggedIn(true);
        setFullName(response.data['name'] || null);
        setGivenName(response.data['given_name'] || null);
        setFamilyName(response.data['family_name'] || null);
      })
      .catch((err) => {
        // Do nothing, this will happen often if the user is not logged in.
      })
      .then(() => {
        setReady(true);
      });
  }, []);

  // watcher for loggedIn
  useEffect(() => {
    if (!loggedIn) {
      setFullName(null);
      setFamilyName(null);
      setGivenName(null);
    }
  }, [loggedIn]);

  // Update OuathContext Value
  useEffect(() => {
    setProviderValue({
      ready,
      loggedIn,
      fullName,
      givenName,
      familyName,
      setLoggedIn,
      setFullName,
      setGivenName,
      setFamilyName,
    });
  }, [loggedIn, fullName, givenName, familyName, ready]);

  return (
    <OauthContext.Provider value={providerValue}>
      {children}
    </OauthContext.Provider>
  );
};

export default OauthContext;

export { OauthContextProvider };
