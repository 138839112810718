import React, { useEffect, useState } from 'react';
import axios from 'axios';

const defaultValue = {
  domainQueryComplete: false,
  domainLookup: {},
};

/**
 * The Context singleton
 */
const DomainContext = React.createContext(defaultValue);

/**
 * Provider Wrapper
 */
const DomainContextProvider = ({ children }) => {
  const [domainQueryComplete, setDomainQueryComplete] = useState(false);
  const [domainLookup, setDomainLookup] = useState({});
  const [providerValue, setProviderValue] = useState(defaultValue);

  // Call the API to get the domain lookup.
  useEffect(() => {
    axios
      .get('/app/domain-lookup')
      .then((response) => {
        setDomainLookup(response.data);
      })
      .catch((err) => {
        // This really shouldn't return an error so we will log it.
        console.error(err);
      })
      .then(() => {
        // Finally
        setDomainQueryComplete(true);
      });
  }, []);

  // Update provider after axios completes
  useEffect(() => {
    if (domainQueryComplete) {
      setProviderValue({
        domainQueryComplete,
        domainLookup,
      });
    }
  }, [domainQueryComplete, domainLookup]);

  return (
    <DomainContext.Provider value={providerValue}>
      {children}
    </DomainContext.Provider>
  );
};

export default DomainContext;

export { DomainContextProvider };
