/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

import React from 'react';
import { I18nextProvider } from 'react-i18next';
import { OauthContextProvider } from './src/context/OauthContext';
import { DomainContextProvider } from './src/context/DomainContext';
import 'normalize.css';
import './src/styles/styles.scss';
import i18n from './src/i18n';

export const wrapRootElement = ({ element }) => {
  return (
    <DomainContextProvider>
      <OauthContextProvider>
        <I18nextProvider i18n={i18n}>{element}</I18nextProvider>
      </OauthContextProvider>
    </DomainContextProvider>
  );
};
