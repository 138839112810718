module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"gatsby-starter-default","short_name":"starter","start_url":"/","background_color":"#663399","theme_color":"#663399","display":"minimal-ui","icon":"/Users/KCrisanto/Documents/abbott-red/frontend/src/images/favicon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"627b7c6f6c77fced4a03fb96a302a889"},
    },{
      plugin: require('../node_modules/@discoveryed/de-analytics/gatsby-browser.js'),
      options: {"plugins":[],"extraDevHostnames":[{"domain":"qa.futurewellkids.com","region":"US"},{"domain":"qa.futurewellkids.mx","region":"MX"},{"domain":"qa.futurewellkids.ie","region":"IE"},{"domain":"qa.futurewellkids.co","region":"CO"},{"domain":"qa.futurewellkids.co.uk","region":"UK"},{"domain":"qa.futurewellkids.in","region":"IN"}],"extraStageHostnames":[{"domain":"stage.futurewellkids.com","region":"US"},{"domain":"stage.futurewellkids.mx","region":"MX"},{"domain":"stage.futurewellkids.ie","region":"IE"},{"domain":"stage.futurewellkids.co","region":"CO"},{"domain":"stage.futurewellkids.co.uk","region":"UK"},{"domain":"stage.futurewellkids.in","region":"IN"}],"extraProdHostnames":[{"domain":"futurewellkids.com","region":"US"},{"domain":"futurewellkids.mx","region":"MX"},{"domain":"futurewellkids.ie","region":"IE"},{"domain":"futurewellkids.co","region":"CO"},{"domain":"futurewellkids.co.uk","region":"UK"},{"domain":"futurewellkids.in","region":"IN"}],"partnershipId":"cba1ddf7-e31e-449d-955b-42c2f37ad1a8"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
